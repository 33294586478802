.blm-select-outer {
    margin-bottom: 0px;
    .blm-select {
        font-size: 11px;
        font-weight: bold;
        -webkit-appearance: none;
        display: block;
        margin: 7px auto;
        height: 30px;
        width: 30px;
        padding: 0px 0px;
        padding-left: 8px !important;
        box-shadow: 0 2px 0px 0px rgba(2, 8, 8, 0.21) !important;
        border: 0px;
    }
}

.teacher-comments {
    margin-left: 15px;
    background-color: #EEEEEE;
    padding: 5px 0 0 5px;
    border-top: $yellow 2px solid;
    position: relative;
    height: 80px;
    width: 100%;
    z-index: 10;
    input {
        height: 68px;
        border-radius: 10px;
        box-shadow: 0px 2.5px 0px rgba(8, 8, 8, 0.51);
        margin-bottom: 5px;
        &.form-control {
            padding-bottom: 25px;
        }
    }
    &.freeze {
        pointer-events: none;
    }
    button {
        height: 25%;
        padding: 5px 5px 5px 5px;
        margin: 0px 0px 5px 0px;
        width: 100%;
        &.teacher-comment-cancel {
            background-color: #9C9C9C;
            color: white;
            &:hover {
                background-color: #9C9C9C;
                color: white;
            }
            &:focus {
                background-color: #9C9C9C;
                color: white;
            }
        }
    }
}
.comments-container {
    transition: bottom 1s;
    bottom: -85px;
    position: absolute;
    width: 100%;
    &.comments-slide-up{
        bottom: 0px;
    }

}

.toggle-comments {
    /*position: absolute;*/
    /*bottom: -20px;*/
    margin: 0 50% -15px 50%;
    z-index: 9;
    /*left: -20px;*/
    
    .btn {
        font-size: larger;
        border: none;
        height: 40px;
        width: 40px;
        padding: 5px;
        border-radius: 40%;
        i {
            bottom: 10px;
            position: relative;
            margin-right: 0px;
        }
    }
}

.pages .page.blm-canvas-container {
    background-color: transparent;
    z-index: 5;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.freeze-buttons {
    pointer-events: none;
    .print-button,
    .close-blm {
        pointer-events: all;
    }
}

.blm-buttons, .freeze-buttons {
    position: absolute;
    bottom: 0;
    text-align: center;
    right: -50px;
    margin: 0;
    width: 50px;
    background-color: #EEEEEE;
    font-size: 10px;
    .active-button {
  	    border-bottom: solid 3px $blue;
  	}
    .reset i {
      color: $red;
      padding-left: 5px;
    }
    .hl-highlight::after{
        display:none
    }
    .dropdown-menu {
        min-width: 56px;
        width: 56px;
        bottom: 40px;
        left: -12px;

        a {
            padding: 3px;
            width: 45px;
            height: 40px;
            margin: 8px auto;
            border-radius: 4px;
        }
        li {
            width: 45px;
            border-radius: 4px;
            margin: 8px auto;
        }
    }
    .marker-dropdown-container{
        height: 30px;
        margin: 5px auto;

    }
    button{
        display: block;
        font-size: 10px;
        height: 30px;
        width: 40px;
        padding: 0px !important;
        margin: 5px auto;
    }
    button.icon-btn {
        width: 30px;
        overflow: hidden;
        i.close-blm {
            font-size: 23px;
        }
    }
    img {
        max-height: 90%;
        max-width: 90%;
        margin: 0 auto;
        padding: 3px;
    }
}

#printSection,
#printSection * {
    visibility: hidden;
}

#printSection{
    display: none;
}
@media print {
    body * {
        visibility: hidden;
    }
    #printSection,
    #printSection * {
        visibility: visible;
    }
    #printSection {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        visibility: visible;
        display: block;
    }
}
