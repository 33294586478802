@import '../../sass/variables.scss';
.drawer-buttons-container {
  top: 80px;
  left: 200px;
  z-index: 10;
  position: absolute;
  .iconTools {
    margin-top: 10px;
    .btn{
      border-radius: 0;
    }
    .btn-arrow-right{
      position: relative;
    }
    .btn-arrow-right:after {
      content: '';
      position: absolute;
      top: 0;
      width: 0; 
      height: 0; 
      border-top: 23.5px solid transparent;  /* green needs to be tranparent, this is just for easy-understanding display */
      border-bottom: 21px solid transparent; /* red needs to be transparent */
    }

    .btn-arrow-right:after {
      right: -15px;
      // border-left: 15px solid $yellow;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
     }
    
    img {
      height: 25px;
    }

  }
}
.audio-tools {
  bottom: 180px;
  left: 0;
  position: fixed;
  .jp-jplayer {
    .jp-play, .jp-stop {
      min-width: 45px;
      max-width: 45px;
      display: block;
    }
    .jp-play {
      margin-bottom: 10px;
    }
  }
}

.drawer {
  display: block;
  overflow: visible;
  width: 200px;
  background-color: $darkerGray;
  position: fixed;
  top: 0px;
  left: -200px;
  padding: 8px;
  border-right-width: 2px;
  border-right-style: solid;
  transition: left 0.5s ease;
  &.shown {
    display: block;
    left: 0px;
    transition: left 0.5s ease;
  }
  .drawer-list {
    overflow-y: auto;
    overflow-x: hidden;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .drawer-item {
    margin-bottom: 4px;
    span.smaller {
      font-size: 10px;
    }
    &.class-note {
      border: 2px solid $classNotes;
    }
    &.group-note {
      border: 2px solid $groupNotes;
    }
    &.student-note {
      border: 2px solid $studentNotes;
    }
    &.teacher-student-note {
      border: 2px solid $teacherStudentNotes;
    }
  }
}

