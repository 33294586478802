﻿@media (min-width: 992px){
    .container { width: 992px; max-width: 992px; }
}
@media (min-width: 1024px){
	.container { width: 1024px; max-width: 1024px; }
}
@media (min-width: 1280px){
	.container { width: 1280px; max-width: 1280px; }
}

@media (min-width: 1440px){
	.container { width: 1440px; max-width: 1440px; }
}