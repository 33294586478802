﻿.cssload-loader {
	position: absolute;
	z-index: 999;
	margin-left: 30px;
	margin-top: 25px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
	perspective: 440px;
	opacity: 0;
}

.cssload-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	border-radius: 50%;
		-o-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;	
}

.cssload-inner.cssload-one {
	left: 0%;
	top: 0%;
	animation: cssload-rotate-one 1.15s linear infinite;
		-o-animation: cssload-rotate-one 1.15s linear infinite;
		-ms-animation: cssload-rotate-one 1.15s linear infinite;
		-webkit-animation: cssload-rotate-one 1.15s linear infinite;
		-moz-animation: cssload-rotate-one 1.15s linear infinite;
	border-bottom: 2px solid rgb(249,136,84);
}

.cssload-inner.cssload-two {
	right: 0%;
	top: 0%;
	animation: cssload-rotate-two 1.15s linear infinite;
		-o-animation: cssload-rotate-two 1.15s linear infinite;
		-ms-animation: cssload-rotate-two 1.15s linear infinite;
		-webkit-animation: cssload-rotate-two 1.15s linear infinite;
		-moz-animation: cssload-rotate-two 1.15s linear infinite;
	border-right: 2px solid rgba(249,136,84,0.98);
}

.cssload-inner.cssload-three {
	right: 0%;
	bottom: 0%;
	animation: cssload-rotate-three 1.15s linear infinite;
		-o-animation: cssload-rotate-three 1.15s linear infinite;
		-ms-animation: cssload-rotate-three 1.15s linear infinite;
		-webkit-animation: cssload-rotate-three 1.15s linear infinite;
		-moz-animation: cssload-rotate-three 1.15s linear infinite;
	border-top: 2px solid rgb(249,136,84);
}

@keyframes cssload-rotate-one {
	0% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
	}
}

@keyframes cssload-rotate-two {
	0% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
	}
}


@keyframes cssload-rotate-three {
	0% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
	}
}

