@import '../../sass/variables.scss';

.viewer-header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}
.hide-for-now {
  visibility: none;
}
.page-header {
  background-color: $toolbarBg;
  margin: 0;
  padding: 8px 0 0 5px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  position: absolute;
  height: 60px;
  width: 100%;
  z-index: 10;
  top: -60px;
  .item, .item.btn.btn-default {
    border: none;
    padding: 8.5px 17px;
    font-weight: 100;
    margin-right: 15px;
    background-color: $darkGray;
    color: white;
  }
  .book-blm-menu {
    margin-right: 10px !important;
  }
  .btn-group .item, .btn-group .item.btn.btn-default {
    margin-right: 0px !important;
  }
  .closing-button {
    padding-right: 0px;
    .item.btn.btn-default {
      padding: 11px 17px;
      margin-right: 20px;
    }
  }
  button.item{
    user-select: none; //hopefully this fixes the issue of being able to highlight the text in the toolbar
  }
  ul.dropdown-menu.blm {
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    left: 150px;
    max-height: 300px;
    min-height: 300px;
    min-width: 300px;
    max-width: 300px;
    overflow-y: scroll;
  }
  ul.dropdown-menu {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
    min-height: 300px;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }
  .caret {
    margin-right: 15px;
  }
  p, img {
    padding-right: 10px;
    display: inline-block;
    margin: 0;
  }
}
.toggle {
  position: absolute;
  top: -25px;
  margin: 0 50%;
  left: -20px;
  z-index: 9;
  .btn {
    background-color: $yellow;
    color: white;
    font-size: larger;
    border: none;
    height: 40px;
    width: 40px;
    padding: 5px;
    border-radius: 40%;
    i {
      top: 12px;
      position: relative;
      margin-right: 0px;
    }
  }
}

.menu {
  padding-top: 25px;
  font-size: larger;
  .name {
    text-align: left;
  }
  i {
    float: right;
  }
}


.navbar {
  margin: 0;
  z-index: 1;
  border-radius: 0;
  background-color: white;
  height: 65px;
  border-bottom: 1px solid $yellow;
  box-shadow: 3px $yellow;
  box-shadow: 1px 3.5px 2px 0px rgba(8, 8, 8, 0.11);
  padding-left: 20px;
  img.navbar-brand {
    // width: 220px;
    height: 60px;
    padding: 5px;
    position: absolute;
    left: 35px;
    
  }
}