.pull-right.users-name {
    color: black;
    font-family: $mainFont;
    line-height: 3.8;
    font-weight: 300;
}
.downloaded-book {
  position: absolute;
  right: 5px;
  top: 5px;
}

.books-row{
    padding-bottom: 50px;
}
.toolbar .toolbar-page-numbers{
    font-family: $mainFont;
    font-size: 16px;
    margin: 0px;
    padding: 10px 10px
}

.book-bag-content {
    background: #DDDDDD;
    min-height: 100vh;
    padding: 0px;
    box-shadow: 0px 2.5px 10px rgba(8, 8, 8, 0.91);
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    .search-input {
        padding: 35px 0px 0px 0px;
        margin-bottom: 0px;
        margin-left: 7px;
        .form-group .form-control {
            color: black;
            height: 45px;
        }
    }
    .bookbag-search-form{
      height: 52px;
      margin-bottom: 35px;
    }

    .bookbag-search-inputs {
        button {
            width: 100%;
            height: 45px;
            line-height: 1;
            background-color: $blue;
            border-bottom: 2.5px solid #1a6bb1;
        }
        .col-md-5{
            padding-left: 20px;
        }
    }
    .bookbag-searchmode {
        figure {
            margin: 0px 3.5px;
            display: inline-block;
            &:active img{
                border: 3px solid $darkBlue;
                border-radius: 15.5px;
              }
            img {
                max-height: 52px;
                margin: 0px 2.5px 0px 2.5px;
                &.backpack, &.bookshelf {
                    border: 3px solid #1697FE;
                    border-radius: 15px;
                }
            }
            figcaption {
                color: #777;
                font-size: 12px;
                padding-top: 10px;
                margin-bottom: 15px;
                font-weight: 700;
                text-align: center;
            }
        }
    }

    .bookbag-search-images {
      img {
        max-height: 55px;
        margin: 0px 3.5px 0px 3.5px;
        text-align: center;
        &.backpack, &.all-tags, &.bookshelf, &.social-science-bc, &.math-bc, &.science-bc, &.all-bc, &.fiction-bc, &.non-fiction-bc, &.language-bc {
          border: 3px solid #1697FE;
          border-radius: 15.5px;
        }
      }
      figcaption {
          font-size: 12px;
          color: #777;
          padding-top: 10px;
          text-align: center;
      }
      figure {
          display: inline-block;
          &:active img{
            border: 3px solid $darkBlue;
            border-radius: 15.5px;
          }
      }
      .advanced-search-buttons{
        padding: 0;
        text-align: right;
        .advanced-search, .advanced-search-active {
          text-align: right;
        }
        button.advanced-search, button.advanced-search-active, button.clear-advanced-search {
          line-height: .7;
        }
        .advanced-search, .clear-advanced-search {
          color: $grayBlue;
          &:active{
            color: $darkBlue;
          }
        }
        .advanced-search-active {
          color: red;
          text-decoration: underline;
        }
      }
    }
    .btn-blue {
        background-color: $blue;
        border-bottom: 2.5px solid #1a6bb1;
        color: white;
    }
    .btn-white {
        background-color: white;
        border-bottom: 2.5px solid rgba(8, 8, 8, .51);
        color: $blue;
    }
    .raise-book {
        // position: relative;
        transition: transform .5s;
        transition-timing-function: cubuc-bezier(0.5, 0.31, 0.84, 0.05);
        transform: translateY(-100px);
        // top: -150px;
        z-index: 10;
        // .book-cover{
        //     display: none;
        // }
    }
    .glow {
        filter: drop-shadow(0px 1px 10px rgba(37, 153, 254, 0.65));
    }
    .book-shadow {
        filter: drop-shadow(3px 2.5px 3px rgba(8, 8, 8, .51));
    }
    .book-container {
        margin-top: 50px;
        margin-bottom: 25px;
        padding-left: 12px;
        transition: transform .5s;
        transition-timing-function: cubuc-bezier(0.5, 0.31, 0.84, 0.05);
    }

    .book {
        border-radius: 30px;
        height: 291px;
        width: 228px;
        margin: 0 auto;
        p.book-title {
            position: relative;
            top: 130px;
            font-family: $mainFont;
            margin: 0px 25px;
            min-height: 0px;
            max-height: 0px;
        }
        .book-details {
            position: absolute;
            margin-left: 19px;
            height: 256px;
            width: 207px;
            z-index: 10;
            color: #fff;
            cursor: pointer;
            user-select: none; // this prevents a double click from initiating a text selection
            p.book-title {
                position: relative;
                top: 130px;
                font-family: $mainFont;
                margin: 0px 25px;
                min-height: 20px;
                max-height: 20px;
            }
            .book-reading-level {
                position: absolute;
                bottom: 10px;
                width: 100%;
                padding: 7.5px;
                background: rgba(74, 68, 70, 0.9);
                font-family: $mainFont;
                text-align: center;
                font-weight: 100;
                .grl-hide, .atos-hide, .lex-hide {
                  visibility: hidden;
                }
            }
            .external-link {
                position: relative;
                top: 10px;
                left: 10px;
                width: 30px;
                height: 30px;
                line-height: 1;
                border-radius: 50%;
                i {
                    top: 22%;
                    position: relative;
                    left: 5%;
                }
            }
        }
        .book-button, .add-book-button, .download-book-button {
            width: 100%;
            margin-top: 10px;
            padding: 6px 0px;
            font-family: $mainFont;
        }
    }
    .visible {
        visibility: visible;
    }
    .hidden {
        visibility: hidden;
    }
    .offline-message, .empty-search-message {
      text-align: center;
      font-size: 25px;
      padding: 0;
      color: #555;
      font-weight: 500;
    }
}

// .advanced-search-modal{
  p {
    color: #777;
  }
  .tag span {
    padding: 10px;
    margin: 15px;
    background-color: #777;
  }
// }
.static-modal-update-user-profile {
    padding-top: 64px;
    background-color: rgba(0, 0, 0, 0.3);
    .column-size {
        padding: 0px 25px;
    }
    .modal-dialog {
        width: 42%;
    }
    .modal-title {
        font-family: $headerFont;
        font-weight: 300;
        font-size: 21px;
        padding-top: 10px;
        color: black;
    }
    .modal-content {
        background-color: #DDDDDD;
        padding: 10px;
    }
    .modal-header,
    .modal-body {
        border-bottom: none;
        .form-group {
            input {
                color: #555;
                font-size: 22px;
                font-weight: 400;
                border-bottom: 2.8px solid #CBCBCB;
                &::placeholder {
                    color: #555;
                    font-weight: 400;
                }
            }
            .form-control {
                height: 52px;
            }
        }
    }
    .password-text {
        padding: 10px 0 10px 1px;
        color: black;
        font-size: 12px;
        text-align: left;
        .first-row {
            padding-bottom: 5px;
        }
    }
}

.navbar-nav .main-header-item a {
    line-height: 32px;
    &:focus{
        outline: none;
    }
}
.navbar-nav .main-header-item {
    &:active{
        background-color: $backgroundGrey;
    }
}
.navbar-nav>.main-header-item.disabled>a {
    color: #aaa;
}

.book-bag-header .navbar-collapse{
    padding-right: 30px;
    
}
.header-logo img{
    height: 60px;
padding: 5px;
}