@import '../../sass/variables.scss';
.App .btn.close-book {
    position: fixed;
    right: 5px;
    top: -5px;
    font-size: 30px;
    z-index: 10;
    text-shadow: 0 3px 0 rgba(8, 8, 8, 0.71);;

}
.pages-visible-buttons{
    position: fixed;
    top: 80px;
    right: 0;
    
    z-index: 10;
    .btn {
        display: block;
        margin-top: 10px;
        border-bottom-right-radius: 0;
    }
}
.audio-highlight-text{
    background-color: rgb(198, 230 ,247);
    span{
        background-color: rgb(198, 230 ,247);
    }
}

.pages.blm-mode{
    &.two-visible .page-left{
        // width: 48.2%;
        // width: 49.8%;
    }
    .pageIndex.next{
        // left: 48.2%;
        // left: 49.8%;
    }
    // .page.right.page-right{
    //     visibility: hidden;
    // }
}
.pages {
    position: relative;
    margin: 0 auto 0 auto;
    padding-top: 20px;
    margin-bottom: 120px;
    width: 80vw;
    height: 86vh;
    user-select: text; 
    opacity: 0;
    // &.blm-pages {
    //     margin: 20px 15px 0 40px;
    // }
    &.note-mode {
        cursor: url('../../images/note_cursor.jpg'), auto;
    }
    &.hl-mode {
        cursor: url('../../images/icon_hl_yellow_cursor.png') 2 20, auto;
    }
    &.eraser-mode {
        cursor: url('../../images/icon_eraser_cursor.png') 2 20, auto;
    }
    &.hl-mode-hl-yellow {
        cursor: url('../../images/icon_hl_yellow_cursor.png') 2 20, auto;
        ::selection { background-color: $hlyellow; }
    }
    &.hl-mode-hl-green {
        cursor: url('../../images/icon_hl_green_cursor.png') 2 20, auto;
        ::selection { background-color: $hlgreen; }
    }
    &.hl-mode-hl-teal {
        cursor: url('../../images/icon_hl_teal_cursor.png') 2 20, auto;
        ::selection { background-color: $hlteal; }
    }
    &.hl-mode-hl-orange {
        cursor: url('../../images/icon_hl_orange_cursor.png') 2 20, auto;
        ::selection { background-color: $hlorange; }
    }
    &.move-mode {
        cursor: url('../../images/move_icon_cursor.png') 2 20, auto;
    }
    .pageContainer {
        // overflow: hidden;
        // position: absolute; // max-height: 90vh;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // transform: translateZ(0px);
    }
    .page {
        // width: 49.8%;
        // height: 100%;
        background-color: white;
        box-shadow: 2px 3px 2px 0px rgba(8, 8, 8, 0.71); // left: 0px;
        opacity: 1; // transition: all 0.5s ease;
        &.invisible-left {
            opacity: .1;
            position: absolute;
            visibility: hidden;
            // left: -2000%; // transition: all 0.5s ease;
        }
        &.invisible-right {
            opacity: .1;
            position: absolute;
            visibility: hidden;
            // left: 2000%;
        }
        &.single-page {
            width: 99.6%;
            float:left;
            position: absolute;
        }
        &.blm-mode {
            width: 49.8%;
        }
        &.blm-page {
            // width: 44%;
            // right: 0;
            position: absolute;
            // background-color: transparent;
            // canvas {
            //     width: 100% !important;
            //     height: 100% !important;
            // }
        }
    }
    // fix for IE 11
    // .page div:nth-of-type(2){
    //     height: 100%;
    //     width: 100%;
    // }
    .pageIndex {
        position: absolute; // z-index: 100;
        top: 35%;
        &.prev {
            left: -46px;
            opacity: .6;
            &:hover {
                opacity: 1;
            }
            .btn {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
        &.next {
            // right: -46px;
            opacity: .6;
            &:hover {
                opacity: 1;
            }
            .btn {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                img {
                    left: 8px;
                    top: 65px;
                }
            }
        }
        .btn {
            padding-left: 3px;
            padding-right: 3px;
            padding-top: 35px;
            height: 190px;
            width: 46px;
            vertical-align: middle;
            background-color: $darkerGray;
            border: #222222 1px solid;
            color: white;
            img {
                width: 30px;
                height: 56px;
                position: absolute;
                right: 8px;
                top: 65px;
            }
            &:hover{
                background-color: $darkerGray;
            }
            &:focus{
                background-color: $darkerGray;
            }
            &:active{
                background-color: $darkGray;
            }
        }
    }
    .note {
        opacity: .3;
        &.active {
            opacity: 1;
        }
    }
    .note:hover {
        opacity: 1;
    }
}

// #pages {
//   opacity: 0.01;
// }
// teacher-student-popover-note
.popover{
    box-shadow: 0px 10px 15px rgba(0, 0, 0, .4);
    .popover-title {
        text-align: center;
        color: white;
        padding: 2px 14px;
    }
    .popover-content {
        min-width: 251px;
    }
    // .arrow:after {
    //     border-top-color: #D25651 !important;
    //     border-bottom-color: #D25651 !important;
    // }
}
.teacher-student-popover-note {
    background-color: $teacherStudentNotes;
    .popover-title{
        background-color: $teacherStudentNotes;
    }
    .arrow:after {
        border-top-color: $teacherStudentNotes !important;
        border-bottom-color: $teacherStudentNotes !important;
    }

}
.teacher-group-popover-note {
    background-color: $groupNotes;
    .popover-title{
        background-color: $groupNotes;
    }
    .arrow:after {
        border-top-color: $groupNotes !important;
        border-bottom-color: $groupNotes !important;
    }
}
.teacher-class-popover-note {
    background-color: $classNotes;
    .popover-title{
        background-color: $classNotes;
    }
    .arrow:after {
        border-top-color: $classNotes !important;
        border-bottom-color: $classNotes !important;
    }
}
.student-popover-note {
    background-color: $studentNotes;
    .popover-title{
        background-color: $studentNotes;
    }
    .arrow:after {
        border-top-color: $studentNotes !important;
        border-bottom-color: $studentNotes !important;
    }
}
.popover.bottom {
    .arrow:after {
        top: 1.5px
    }
}

.popover-content {
    // height: 120px;
    width: 200px;
}

.btn-popover {
    padding: 1px 8px 1px 8px;
    margin: 2px;
    // background-color: #A43A37;
    // color: #fff;
}

textarea.popover-textarea {
    border: 0px;
    border-radius: 5px;
    margin: 0px;
    width: 100%;
    height: 100%;
    padding: 4px;
    box-shadow: none;
    color: black;
    font-size: 14px;
}

@media screen and (max-width:1024px) and (orientation:landscape) {
  .close-book {
      i {
        font-size: 30px;
        position: absolute;
        right: 10px;
      }
  }
}
