/* This has the CSS for the shell and common screens */
@import 'variables.scss';

html, body {
    height: 100%;
    overflow: hidden;
}
body { background-color: $background; margin: 0; padding: 0; font-family: $mainFont; font-weight: 400; font-size: 16px; line-height: normal;}
h1,h2,h3,h4,h5,h6 { font-weight: 500; font-family: $headerFont; margin-top: 0; }
h1 { font-size: 24px; line-height: 34px; }
h2 { font-size: 20px; line-height: 30px; }
h3 { font-size: 18px; line-height: 24px; }
h4 { font-size: 16px; line-height: 20px; font-weight: 500; }

strong { font-weight: 500; }

.hide {
  display: none !important;
}
.App .form-group input.form-control{
	background: #fff;
    // color: $textColor;
    font-family: $mainFont;
    font-size: 16px;
    padding: 10px 15px;
    
    // border: solid 1px #ccc;
    
	&:focus { border: solid 1px $blue; }
}
.dibs-form .form-group{
  .checkbox{
    line-height: 1.5;
  }
  .form-control, .react-select__control, .react-selectize .react-selectize-control{
    border-radius: 4px;
    border-bottom: 2.8px solid #CBCBCB;
    height: 40px;
  }
}
.App .form-group.has-error input.form-control{
  border-bottom: 2.8px solid #a94442;
}
.App .form-group.has-success input.form-control{
  border-bottom: 2.8px solid #3c763d;
}
// this helps make sure notes placed on top of a word is not screwy
.App .note-mode .t{
  pointer-events: none;
}

.control { margin-bottom: 10px; }
.Select {

	.Select-control {
		padding: 5px 0;
		&:hover { box-shadow: none; }
	}
	.Select-placeholder {
		background: #fff; color: $textColor; font-family: $mainFont; font-size: 16px; padding: 5px 15px; border-radius: 4px; border: solid 1px #ccc;
	}
	input:focus  { border: none !important; }
}

.statusBox { margin: 0 auto; left: 0; right: 0; max-width: 1440px; font-weight: 300; width: 80%; padding: 15px 15px; text-align:center; opacity: 0; position: fixed; bottom: 0; color: #fff;}
#errorBox { background: $red; z-index: 999999; border-top: solid 1px red; }
#successBox { background: $green; border-top: solid 1px green; z-index: 999999; }
#loader { display: none; position: fixed; top: 30px; left: 170px; z-index:10; }
#overlay { display: none; opacity: 0; position: absolute; top: 0; left: 0; background:rgba(0,0,0,.7); z-index: 200; width: 100%; height: 100%; }

.confirm {
    position: absolute; z-index: 200; height: 0; display: none;  margin: 0 auto;
	p { margin-top: 0; }
    .content {padding: 30px 30px !important; float: left; width: auto !important; line-height: 24px; color: #fff !important; background: $darkBlue !important; }
    button { background: $grayBlue; border-color: $grayBlueShadow; margin-right: 10px; margin-top: 30px; }
	button:last-of-type { margin-right: 0;}
}

.rowPadding { margin-top: 15px; }
.small { font-size: 12px; font-weight: 300; }
// .left { float: left; text-align: right; }
// .right { float: right; text-align: right; }
a {
	color: $orange; text-decoration: none;
	&:hover { text-decoration: underline; color: $orange; }
}

*:focus { outline: none; }

button, .button {
	background: $grayBlue; border: none; padding: 10px 15px; border-bottom: 3px solid $grayBlueShadow; border-radius: 4px; font-size: 16px; font-weight: 400;
	i { margin-right: 5px; }
}

.tableRow {
	clear: both; float: left; width: 100%; background: #fefefe; padding: 10px 20px 5px; border-bottom: solid 2px $backgroundWhite;
	&:nth-child(even) { background: #f7f7f7; }
	&:hover { background: #fffbe3; }
	div { margin-top: 5px; float: left; }
	.rowImage {
		float: left; width: 15%; margin-top: 0;
		img { height: 36px; width: auto; border-radius: 4px; border-bottom: 2px solid $grayBlueShadow; }
	}
	.rowName { font-family: $headerFont;  font-weight: 500; cursor: pointer; }
	.rowActions {
		float: right; width: 15%; text-align:right; margin-top: 0;
		i {
			padding: 10px 10px 10px 5px;  opacity: .1; cursor:pointer;
			&:hover { opacity: 1; }
		}
	}
}

.btnClose { background: #fff; border: none; color: #ccc; padding: 15px 0 15px 15px; float: right; margin-top: -14px; font-size: 18px; }
.btnForm { width: 100%; margin-top: 20px; }
// #pageWrapper { box-shadow: 0 0 20px rgba(0,0,0,.6); height: 100%; border-top: solid 2px $yellow; position: absolute; margin: 0 auto; left: 0; right: 0; max-width: 1440px; width: 80%; }
// #leftSide {
// 	background: $blue; height: 2000px; box-shadow: inset -0px 0 10px rgba(0,0,0,.2); height: 100%; padding: 0;
// 	#logo { background: $darkBlue; padding: 30px 20px 20px; position: relative; z-index: 100; }
// 	a { color: #fff; text-decoration: none; }
// }
// #rightSide {
// 	background: $backgroundWhite; height: 2000px; padding: 0;
// 	#header {
// 		background: $blue; height:84px; text-align: right; position: fixed; margin: 0 auto; left: 0; right: 0; max-width: 1440px; width: 80%; padding: 25px 15px;

// 		a { color: #fff; font-size: 18px; margin-left: 20px; }
// 	}

// 	#content {
// 		padding: 30px; margin-top: 70px; overflow-y: auto; overflow-x: hidden; min-height:400px;
// 	}
// }

#sideMenu {
	color: #fff; font-size: 14px; margin-top: 20px;
	i { margin-right: 15px; text-shadow: 0 2px 3px rgba(0,0,0,.6); }
	ul {  padding: 0; margin: 0; }
	li { list-style: none; padding: 10px 20px; margin: 0;  border-bottom: solid 2px transparent;}
	.active {
		background: $backgroundWhite; border-bottom: solid 2px $darkBlue;
		i {  text-shadow: 0 2px 3px rgba(180, 185, 194,.6); }
		a { color: $darkBlue; }
	}
}

#headerMenu {
	 color: #fff; padding-right: 20px; margin-top: 10px;
	 .firstName { font-family: $headerFont; margin-right: 40px; font-size: 18px; }
	 i { margin-right: 20px; }
	 i:last-of-type { margin-right: 0; }
}

.dashWrapper { width:30%; margin-right: 2%; float: left; margin-bottom: 2%; }
.dashBox {
	cursor: pointer; background: #fff; font-weight: 500; font-family: $headerFont; font-size: 20px; padding: 30px;  text-align: center; color: $blue; border-top-right-radius: 4px; border-top-left-radius: 4px;
	.title { line-height: 30px; }
}
.dashBar { text-shadow: 0 2px 2px rgba(145, 129, 39, .8);background: $yellow; padding-top: 5px; color: #fff; font-size: 16px; padding: 5px 30px 0;  height: 30px; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; border-bottom: solid 3px $yellowShadow; }

.transition { -webkit-transition: all .3s ease-in-out;  -moz-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out; }

// Alloy Editor overrides
#editor, .editor {
	border: solid 1px #ccc; border-radius: 4px; padding: 10px 15px; margin-bottom: 15px; height: 80px; overflow-y: auto;
	&:focus { border: solid 1px $darkBlue; }
	strong { font-weight: 700; }
	p { margin-top: 0; margin-bottom: 5px; }
}
.ae-toolbar-styles, .ae-toolbar-add, .ae-toolbar { z-index: 9999; }
.ae-ui .ae-toolbar, .ae-ui [class^=ae-toolbar-] { background: $orange; }
.ae-ui .ae-arrow-box.ae-arrow-box-bottom:after, .ae-ui .ae-arrow-box.ae-arrow-box-left:after, .ae-ui .ae-arrow-box.ae-arrow-box-right:after, .ae-ui .ae-arrow-box.ae-arrow-box-top-left:after, .ae-ui .ae-arrow-box.ae-arrow-box-top-right:after, .ae-ui .ae-arrow-box.ae-arrow-box-top:after { color: $orange; }
.ae-editable ::selection { background: #fef8d8 !important; }

.App {
	.container-fluid {
			overflow: hidden;
	}
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.hl-yellow {
  background-color: $hlyellow;
  &.drawer-item {
    background-color: white;
    border-bottom: 3px solid $hlyellow !important;
  }
}

.hl-teal {
  background-color: $hlteal;
  &.drawer-item {
    background-color: white;
    border-bottom: 3px solid $hlteal !important;
  }
}

.hl-dark-blue {
  background-color: $markerBlue;
  &.drawer-item {
    background-color: white;
    border-bottom: 3px solid $markerBlue !important;
  }
}

.hl-black {
  background-color: #000;
  &.drawer-item {
    background-color: #000;
    border-bottom: 3px solid #000 !important;
  }
}

.hl-green {
  background-color: $hlgreen;
  &.drawer-item {
    background-color: white;
    border-bottom: 3px solid $hlgreen !important;
  }
}

.hl-orange {
  background-color: $hlorange;
  &.drawer-item {
    background-color: white;
    border-bottom: 3px solid $hlorange !important;
  }
}

.hl-underline, .hl-underline:hover {
  text-decoration: underline !important;
}

.hl-strikethrough, .hl-strikethrough:hover {
  text-decoration: line-through !important;
}

button.btn {
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 400;
  text-transform: capitalize;
  font-family: $headerFont;
  border-bottom: 3px solid $grayBlueShadow; // this gets overridden by theme.json

  i {
    margin-right: 5px;
  }
  &.btn-link{
    border-bottom: none;
  }
  // &.btn-primary{
  //   color: #fff;
  //   background: $grayBlue;
	// 	border-color: $blue;
  //   border-bottom: 3px solid $grayBlueShadow;
  // }
  // &.btn-info{
  //   color: #fff;
  //   background: $grayBlue;
	// 	border-color: $blue;
  //   border-bottom: 3px solid $grayBlueShadow;
  // }
  // &.btn-warning{
  //   background-color: $yellow;
	// 	// border-bottom: 2px solid #df8a13;
  //   border-bottom: 3px solid $darkerGray;
  //   // box-shadow: 0 3px 0px 0px rgba(8, 8, 8, 0.71);
  //   &:hover{
  //     background-color: $yellow;
  //   }
  //   &:active{
  //     background-color: $darkerYellow;
  //   }
  // }
  // &.btn-default{
	// 	box-shadow: 0 3px 0px 0px rgba(8, 8, 8, 0.71);
  //   // &:active{
  //   //   background-color: red;
  //   // }
  //   background-color: $backgroundWhite;
  //   &:focus{
  //     // background-color: $backgroundGrey; // switch to this if we want to be accessible
  //     background-color: $backgroundWhite;
  //   }
  //   &:hover{
  //     background-color: $backgroundWhite;
  //   }
  //   &:active{
  //     background-color: $backgroundGrey;
  //   }

  // }
  &:focus{
    outline: none !important;
  }

  &.icon-btn i{
    margin: 0;
  }
  &.btn-link{
    text-decoration: underline;
  }
}
.modal-container {
  position: relative;
}
.modal-container .modal, .modal-container .modal-backdrop {
  position: absolute;
}
textarea[data-fabric-hiddentextarea]{
  position: fixed !important;
  top: 10px !important;
  left: 10px !important;
}
.common-modal .modal-content{
  background-color: #DDDDDD;
  color: black;.react-selectize.default.root-node.multi-select {
	  width: 100%;
	}
}
.toast.toast-warning{
  background-color: $darkOrange;
}
.redux-toastr .toastr .close-toastr {
  width: 15% !important;
  font-size: 16px !important;
}
.redux-toastr .toastr .rrt-middle-container{
  padding-top: 15px !important;
}
// a CSS solution to forcing whole word selection, but it is causing wierd issues with the eraser tool and markups created before this user-select:all was turned on;
.t .tappable{
  user-select: all;
}
// collaps the extra space character we are adding before the closing </span> we are adding.  which was added to fix an issue with double clicking to select a word on some books.
.pages .t {
  white-space: nowrap;
}
